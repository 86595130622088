@import 'tailwindcss/base';

@layer base {
    body, #root {
        @apply bg-white md:bg-grey-1 text-blue-4 text-14 font-inter font-normal;
        @apply dark:bg-blue-4 dark:md:bg-blue-3 dark:text-grey-1;
    }

    h1 {
        @apply text-20;
    }

    h2 {
        @apply text-16 font-semibold;
    }

    h3 {
        @apply text-16;
    }

    h4 {
        @apply text-16;
    }

    h5 {
        @apply text-16;
    }

    h6 {
        @apply text-base;
    }

    button, a {
        @apply outline-none focus:outline-none;
    }

    input {
        @apply appearance-none;
    }
}
