@import 'tailwindcss/utilities';

@layer utilities {
    .loading-skeleton {
        @apply bg-primary bg-opacity-30 animate-pulse rounded-full;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .hide-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .legal ul {
        @apply list-disc list-outside ml-20;
    }

    .legal ul li {
        @apply py-10;
    }
}