.animate-bounce-2s {
    animation: bounce-2 2s infinite;
}

@keyframes bounce-2 {
    0%, 100% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
        transform: translateY(-8px);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

.animate-bounce-2s-invert {
    animation: bounce-2-invert 2s infinite;
}

@keyframes bounce-2-invert {
    0%, 100% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
        transform: translateY(8px);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}