.layout-header {
    @apply hidden md:fixed w-full md:flex items-center justify-center h-60 z-40;
    @apply bg-white shadow-header;
    @apply dark:bg-blue-1 dark:shadow-none;
}

.layout-header-content {
    @apply flex justify-between w-full;
}

.layout-header-mobile {
    @apply fixed w-full flex items-center bg-blue-4 md:hidden h-[75px] px-20 z-30;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
}

.layout-header-mobile-content {
    @apply grid grid-cols-3 w-full items-center;
}